import React from "react";


const UnderConstruction =  () => {
    return(
        <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="col-lg-10 mt-5">
                  <h1 className="animate__animated animate__backInDown">
                    <h1 className="animate__animated animate__backInDown">
                      Crafted
                      <br /> <span className="block-light-h1">Wave</span>
                    </h1>
                  </h1>
                  <p className="animate__animated animate__backInLeft">
                  🚧 We're Building Something Great! 🚧 <br/>

                    Thank you for visiting! Our website is currently under construction as we work hard to bring you an even better experience. <br/>

                    We can't wait to share our exciting new updates with you soon! Please check back later, or follow us on social media for the latest news and launch updates.<br/>

                    Stay tuned – something wonderful is on its way!
                  </p>
                  
                  <ul className="nav col-md-10 justify-content-start list-unstyled d-flex animate__animated animate__backInLeft">
                    <li>
                      <i className="bi bi-instagram"></i> Instagram{" "}
                    </li>
                    <li className="ms-3">
                      <i className="bi bi-facebook"></i> Facebook
                    </li>
                    <li className="ms-3">
                      <i className="bi bi-twitter"></i> twitter
                    </li>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </>
    )
}

export default UnderConstruction