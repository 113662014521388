// import logo from "./logo.svg";
import "./App.css";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap Icons CSS
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/home";
import UnderConstruction from "./Pages/under-construction";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<UnderConstruction />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
