import React from "react";
import image_01 from "../Assets/img/01.jpg";
import image_02 from "../Assets/img/02.jpg";
const Slider = () => {
  return (
    <>
      <div id="carouselExampleIndicators" className="carousel slide block-slider-inner">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner block-slider-inner">
          <div className="carousel-item active">
            <img src={image_01} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={image_02} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={image_01} className="d-block w-100" alt="..." />
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
