import React from "react";
import Nav from "../Components/nav";
import Slider from "../Components/home_slider";

const Home = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 block-left order-2">
            <div className="row justify-content-center">
              <div className="col-lg-10 block-container-logo">
                <h1 className="animate__animated animate__backInDown">
                  <h1 className="animate__animated animate__backInDown">
                    Crafted
                    <br /> <span className="block-light-h1">Wave</span>
                  </h1>
                </h1>
                <p className="animate__animated animate__backInLeft">
                  CraftedWave Digital Agency is a forward-thinking digital
                  agency dedicated to helping brands make waves in the digital
                  landscape. With a team of skilled creatives and strategists,
                  we specialise in crafting tailored solutions that elevate
                  brands and captivate audiences.
                </p>
                <p className="animate__animated animate__backInLeft">
                  From cutting-edge website design to data-driven digital
                  marketing strategies, our mission is to merge creativity with
                  technology, creating impactful and memorable digital
                  experiences. At CraftedWave, we believe every brand has a
                  unique story, and our passion lies in telling that story
                  through powerful digital channels. We’re here to bring your
                  vision to life, enhance your digital presence, and drive
                  meaningful growth, one crafted wave at a time.
                </p>
                <ul className="nav col-md-10 justify-content-start list-unstyled d-flex animate__animated animate__backInLeft">
                  <li>
                    <i className="bi bi-instagram"></i> Instagram{" "}
                  </li>
                  <li className="ms-3">
                    <i className="bi bi-facebook"></i> Facebook
                  </li>
                  <li className="ms-3">
                    <i className="bi bi-twitter"></i> twitter
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 block-right order-1">
            <Slider />
            <Nav />
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
